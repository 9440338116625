<template>
  <div class="actions-group two-actions">
    <div class="group-action" @click="startRfp">
      <svg-icon name="new-project" class="base-icon"></svg-icon>Submit New RFP
    </div>

    <rehire-lawyer
      :lawyers="lawyers">
    </rehire-lawyer>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import RehireLawyer from 'vue-app/marketplace/client/dashboard/rehire-lawyer.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DashActions',

  components: {
    RehireLawyer,
    SvgIcon
  },

  props: {
    lawyers: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    rfpUrl() {
      return this.currentClient.wework ? '/public-app/rfp/wework' : '/client-app/requests/new';
    }
  },

  methods: {
    startRfp() {
      window.location = this.rfpUrl;
    }
  }
};
</script>

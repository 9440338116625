<template>
  <div id="workspace-users" class="shadowed-box clearfix">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-7">
          <span class="text-uppercase">Your Team</span>
          <span class="normal-weight left-10">({{ numUsersText }})</span>
        </div>

        <div class="col-sm-5 text-right-not-xs">
          <workspace-invite-modal
            :current-user="currentUser"
            :workspace="workspace"
            :on-invite-sent="onInviteSent">
            <template #modal-trigger="{ openModal}">
              <a href="" class="bold-link blue-link size-text-12px" @click.prevent="openModal" v-if="allowAddTeamMembers"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New User</a>
            </template>
          </workspace-invite-modal>
        </div>
      </div>
    </div>

    <div v-if="currentUser" :class="['top-30 left-30 right-30', { 'bottom-30': isLoading }]">
      <loading-section name="workspaceUsers">
        <div class="row">
          <div class="col-sm-4 bottom-30" v-for="user in workspaceUsers" :key="user.id">
            <workspace-user
              :user="user"
              :current-user="currentUser"
              :on-message-user="messageUser">
            </workspace-user>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import WorkspaceUser from 'vue-app/marketplace/client/workspaces/workspace-user.vue';
import WorkspaceInviteModal from 'vue-app/shared/components/workspaces/workspace-invite-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'WorkspaceUsers',

  components: {
    WorkspaceUser,
    WorkspaceInviteModal,
    SvgIcon,
    LoadingSection
  },

  props: {
    currentUser: Object,

    workspaceUsers: Array,

    onInviteSent: {
      type: Function,
      default: () => {}
    },

    onMessageUser: Function,

    workspace: {
      type: Object,
      required: true
    }
  },

  computed: {
    isLoading() {
      return LoadingService.isLoading('workspaceUsers');
    },

    numUsersText() {
      return this.$pluralize('User', this.workspaceUsers.length, true);
    },

    allowAddTeamMembers() {
      return !this.workspace.isScout;
    }
  },

  methods: {
    messageUser(user) {
      this.onMessageUser(user);
    }
  }
};
</script>

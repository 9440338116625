<template>
  <priori-modal ref="modal" modal-id="project-payments-modal" title="Project Payment Details">
    <template #modal-trigger="{ openModal }">
      <a href="" class="size-text-11px normal-weight" @click.prevent="openModal">Details</a>
    </template>

    <template #default>
      <div class="row bottom-10">
        <div class="col-xs-6 semibold-weight">
          Legal Fees:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(legalFees) }}
        </div>
      </div>

      <div class="row bottom-10">
        <div class="col-xs-6 semibold-weight">
          Disbursements:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(payments.disbursement) }}
        </div>
      </div>

      <div class="row bottom-10">
        <div class="col-xs-6 semibold-weight">
          Management Fees:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(payments.management) }}
        </div>
      </div>

      <div class="row bottom-10">
        <div class="col-xs-6 semibold-weight">
          Transaction Fees:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(payments.transactionFees) }}
        </div>
      </div>

      <div class="row bottom-10" v-if="hasAdjustment">
        <div class="col-xs-6 semibold-weight">
          Adjustment:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(payments.adjustment) }}
        </div>
      </div>

      <div class="row bottom-10" v-if="hasCredit">
        <div class="col-xs-6 semibold-weight">
          Credits Applied:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(payments.creditsUsed) }}
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-xs-6 semibold-weight">
          Total Paid:
        </div>

        <div class="col-xs-6 text-right">
          {{ formatCurrency(total) }}
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import { sumBy } from 'lodash';

export default {
  name: 'ProjectPaidModal',

  components: {
    PrioriModal
  },

  props: {
    payments: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasAdjustment() {
      return !!this.payments.adjustment;
    },

    hasCredit() {
      return !!this.payments.creditsUsed;
    },

    legalFees() {
      return sumBy([
        this.payments.flatRate,
        this.payments.billedHour,
        this.payments.retainer,
        this.payments.prepaidRealization
      ], n => (n || 0));
    },

    total() {
      return sumBy([
        this.payments.flatRate,
        this.payments.billedHour,
        this.payments.retainer,
        this.payments.prepaidRealization,
        this.payments.disbursement,
        this.payments.management,
        this.payments.transactionFees,
        this.payments.adjustment,
        this.payments.creditsUsed
      ], n => (n || 0));
    }
  },

  methods: {
    formatCurrency(amount) {
      return CurrencyFilter.filter((amount || 0) / 100.0);
    }
  }
};
</script>

<template>
  <div>
    <label>Lawyer <span class="inline-help required">*</span></label>

    <dropdown ref="dropdown" class="k-dropdown" v-model="dropdownOpen">
      <button type="button" role="button" id="k-toggle-button-rehire" :class="['dropdown-toggle', { 'invalid': invalid, 'placeholder': !selectedLawyer }]">
        <span v-if="!selectedLawyer">Select Lawyer</span>
        <span class="semibold-weight" v-if="selectedLawyer">{{ selectedLawyer.fullName }}</span>
        <svg-icon name="caret-down" class="caret-down"></svg-icon>
        <svg-icon name="caret-up" class="caret-up"></svg-icon>
      </button>

      <template #dropdown>
        <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
          <li role="menuitem" class="menu-item" v-for="lawyer in lawyers" :key="lawyer.id" @click="onSelect(lawyer)">
            <div class="dropdown-row-inner">
              <profile-photo :user="lawyer" :small="true"></profile-photo>
              <span class="semibold-weight left-20">{{ lawyer.fullName }}</span>
            </div>
          </li>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';

export default {
  name: 'LawyerDropdown',

  components: {
    ProfilePhoto,
    SvgIcon
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    lawyers: {
      type: Array,
      required: true
    },

    invalid: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dropdownOpen: false,
      selectedLawyer: null
    };
  },

  computed: {
  },

  methods: {
    onSelect(lawyer) {
      this.selectedLawyer = lawyer;

      this.$emit('input', lawyer);
      this.$emit('change', lawyer);
    },

    reset() {
      this.selectedLawyer = null;
    }
  }
};
</script>

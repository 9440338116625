<template>
  <div v-if="currentLawyer.isScoutAdmin">
    <div class="titlebar with-button">
      <div class="row">
        <div class="col-sm-8 col-md-9">
          <div class="medium-page-heading top-6">
            Users
          </div>
        </div>

        <div class="col-sm-4 col-md-3 top-20-xs text-right-not-xs">
          <edit-lawyer-permissions v-if="billingSystemsLoaded" :on-save="saveLawyer" :employee-id-is-required="employeeIdIsRequired" :billing-systems="billingSystems">
            <template #modal-trigger="{ openModal }">
              <button type="button" class="secondary-btn-blue fit-content" @click="openModal">Add New Users</button>
            </template>
          </edit-lawyer-permissions>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30">
            <a href="/scout-firm/settings" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Admin Settings</a>
          </div>

          <div class="bottom-30" v-show="searchableLawyers.length">
            <loading-section name="searchable-lawyers">
              <typeahead-filter
                :options="searchableLawyers"
                option-label-key="fullName"
                option-value-key="id"
                placeholder-text="Search for a user..."
                :results-count="resultsCount"
                :on-apply-filter="applyFilter">
              </typeahead-filter>
            </loading-section>
          </div>

          <div class="resource-list static-list size-text-12px">
            <div class="list-header">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-4">
                  <div class="row tight-columns vertical-center-not-xs">
                    <div class="col-sm-5">
                      <dynamic-order-arrows
                        class="vertical-center"
                        :order="order"
                        order-by="name"
                        :initial-order="'asc'"
                        :on-sort="sort"
                        :reverse="true">
                        <div>
                          Name
                        </div>
                      </dynamic-order-arrows>
                    </div>

                    <div class="col-sm-7">
                      Email
                    </div>
                  </div>
                </div>

                <div class="col-sm-7">
                  <div class="row tight-columns vertical-center-not-xs">
                    <div class="col-sm-1-5">
                      <span class="right-2">Profile</span>

                      <div class="vertical-center inline-block">
                        <svg-icon id="profile-tooltip" name="info" class="base-icon smaller"></svg-icon>
                      </div>

                      <tooltip
                        target="#profile-tooltip"
                        placement="bottom"
                        custom-class="tooltip-white"
                        text="If Profile is marked ‘Yes’ then this user will be included in Client searches and have a lawyer profile that Clients can access. If Profile is marked as ‘No’, the user will not have a profile nor be included in client search.">
                      </tooltip>
                    </div>

                    <div class="col-sm-1-5">
                      <span class="right-2">Editor</span>

                      <div class="vertical-center inline-block">
                        <svg-icon id="editor-tooltip" name="info" class="base-icon smaller"></svg-icon>
                      </div>

                      <tooltip
                        target="#editor-tooltip"
                        placement="bottom"
                        custom-class="tooltip-white"
                        text="If Editor is marked ‘Yes’ then this user will be able to edit other users’ profiles and your law firm profile. If Editor is marked as ‘No’, the user will only be able to edit their own profile.">
                      </tooltip>
                    </div>

                    <div class="col-sm-1-5">
                      <div class="d-flex-not-xs wrap">
                        <div class="flex-3 inline-block-only-xs">
                          View Client Data
                        </div>

                        <div class="flex-1 vertical-center-not-xs inline-block-only-xs">
                          <svg-icon id="client-access-tooltip" name="info" class="base-icon smaller"></svg-icon>
                        </div>

                        <tooltip
                          target="#client-access-tooltip"
                          placement="bottom"
                          custom-class="tooltip-white"
                          text="If View Client Data is marked ‘Yes’ then this user will have access to the Clients tab and be able to view client-specific rates for other attorneys in your workspace. If View Client Data is marked as ‘No’, the user will not have access to the Clients tab and will not be able to see client-specific rates.">
                        </tooltip>
                      </div>
                    </div>

                    <div class="col-sm-1-5">
                      User Type
                    </div>

                    <div class="col-sm-1-5">
                      <span class="right-2">RFP</span>

                      <div class="vertical-center inline-block">
                        <svg-icon id="rfq-tooltip" name="info" class="base-icon smaller"></svg-icon>
                      </div>

                      <tooltip
                        target="#rfq-tooltip"
                        placement="bottom"
                        custom-class="tooltip-white"
                        text="If RFP is marked ‘Yes’, this user will have access to the RFP feature, which includes the ability to view and respond to all incoming RFPs. If RFP is marked ‘No’, this user will not have access to the RFP feature and will not be able to view or respond to incoming RFPs.">
                      </tooltip>
                    </div>
                  </div>
                </div>

                <div class="col-sm-1 text-right-not-xs">
                  Actions
                </div>
              </div>
            </div>

            <loading-section name="lawyer-permissions">
              <div class="list-item no-hover" v-for="lawyer in lawyers" :key="lawyer.id">
                <user-list-item
                  :lawyer="lawyer"
                  :employee-id-is-required="employeeIdIsRequired"
                  :billing-systems="billingSystems"
                  :on-save="saveLawyer">
                </user-list-item>
              </div>

              <div class="list-item empty gray-text size-text-16px" v-if="!lawyers.length">
                No Users
              </div>

              <priori-pagination
                class="scout-pagination top-15"
                :current-page="page"
                :total-entries="resultsCount"
                :per-page="10"
                @change="changePage">
              </priori-pagination>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserListItem from 'vue-app/scout/firm/settings/user-list-item.vue';
import EditLawyerPermissions from 'vue-app/scout/firm/settings/edit-lawyer-permissions.vue';
import TypeaheadFilter from 'vue-app/scout/shared/typeahead-filter.vue';
import DynamicOrderArrows from 'vue-app/shared/components/dynamic-order-arrows.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import NotificationService from 'vue-app/shared/services/notification-service';
import LoadingService from 'vue-app/shared/services/loading-service';
import ScoutLawyer from 'resources/scout/scout-lawyer.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import BillingSystem from 'resources/billing-system.js';
import { mapState } from 'pinia';
import { sortBy } from 'lodash';

export default {
  name: 'ScoutFirmUsers',

  components: {
    UserListItem,
    EditLawyerPermissions,
    TypeaheadFilter,
    DynamicOrderArrows,
    SvgIcon,
    LoadingSection,
    PrioriPagination
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      metadata: {},
      lawyers: [],
      searchableLawyers: [],
      billingSystems: [],
      page: 1,
      order: { name: 'asc' },
      selectedUserId: null,
      billingSystemsLoaded: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentLawyer: 'currentUser' }),

    resultsCount() {
      return this.metadata.total || 0;
    },

    employeeIdIsRequired() {
      return this.currentLawyer.ssoKey === 'employee_number';
    }
  },

  mounted() {
    this.loadSearchableLawyers();
    this.loadBillingSystems();
  },

  methods: {
    sort(sortBy) {
      const sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';

      this.order = { [sortBy]: sortOrder };
      this.loadUsers();
    },

    applyFilter(selectedUserId) {
      this.page = 1;
      this.selectedUserId = selectedUserId;

      return this.loadUsers();
    },

    loadUsers() {
      let params = {
        page: this.page,
        order: this.order,
        lawyer_ids: this.selectedUserId ? [this.selectedUserId] : []
      };

      LoadingService.loading('lawyer-permissions');

      return ScoutLawyer.lawyerPermissionSettings(params)
        .then((response) => {
          this.metadata = response.data.metadata;
          this.lawyers  = response.data.lawyers;

          LoadingService.done('lawyer-permissions');
        })
        .catch(() => {
          window.location = '/scout-firm/settings';
        });
    },

    loadSearchableLawyers() {
      LoadingService.loading('searchable-lawyers');

      ScoutLawyer.lawyerPermissionSettings({ view: 'minimal' })
        .then((response) => {
          this.searchableLawyers = sortBy(response.data.lawyers, 'fullName');

          LoadingService.done('searchable-lawyers');
        })
        .catch(() => {
          window.location = '/scout-firm/settings';
        });
    },

    loadBillingSystems() {
      BillingSystem.query({ view: 'base' })
        .then(response => {
          this.billingSystems       = response;
          this.billingSystemsLoaded = true;
        })
        .catch(() => {
          window.location = '/scout-firm/settings';
        });
    },

    changePage(page) {
      this.page = page;
      this.loadUsers();
    },

    saveLawyer(lawyerParams) {
      if (lawyerParams.id) {
        return this.updateLawyer(lawyerParams);
      }
      else {
        return this.createLawyer(lawyerParams);
      }
    },

    createLawyer(lawyerParams) {
      lawyerParams.invited_by_type = 'Lawyer';
      lawyerParams.invited_by_id = this.currentLawyer.id;
      lawyerParams.law_firm_id = this.currentLawyer.lawFirmId;

      return ScoutLawyer.save({ lawyer: lawyerParams, lawyer_application: {} })
        .then((lawyer) => {
          this.loadSearchableLawyers();
          this.loadUsers();

          NotificationService.success('You successfully added a new user.');
          return lawyer;
        })
        .catch((error) => {
          NotificationService.error('An error has occurred. Please contact Priori for assistance.');
          throw error;
        });
    },

    updateLawyer(lawyerParams) {
      return ScoutLawyer.update({ id: lawyerParams.id, lawyer: lawyerParams, view: 'lawyer_permission_settings' })
        .then((lawyer) => {
          if (lawyerParams.id === this.currentLawyer.id) {
            window.location = '/scout-firm/settings/users';
            return lawyer;
          }

          this.loadSearchableLawyers();
          this.loadUsers();

          NotificationService.success('You successfully updated the user.');
          return lawyer;
        })
        .catch((error) => {
          NotificationService.error('An error has occurred. Please contact Priori for assistance.');
          throw error;
        });
    }
  }
};
</script>

<style scoped lang="scss">
  .list-header {
    padding: 10px 20px 10px 30px;

    [class*="col-"] {
      .base-icon {
        margin-bottom: 2px;
      }
    }
  }

  .d-flex-not-xs.wrap {
    flex-wrap: wrap;
  }

  .inline-block-only-xs {
    display: inline-block;

    @media (min-width: 768px) {
      display: flex;
    }
  }
</style>

<template>
  <opportunities-layout>
    <div class="vertical-center-not-xs flex-space-between opportunity-nav-container top-20-xs bottom-20">
      <div>
        <a href="/public-app/opportunities" class="color-purple bold-weight size-text-14px">Back To All Opportunities</a>
      </div>

      <div v-if="!submittedApplication" class="d-flex nav-btns-container top-20-xs">
        <button type="button" class="primary-btn-purple nav-btn" @click="openForm">Apply</button>
      </div>
    </div>

    <div class="opportunity-content-container bottom-20">
      <div v-if="!submittedApplication">
        <color-message-bar
          v-if="shouldShowTag"
          class="bottom-20"
          :color="colorMessageBarColor"
          :header="colorMessageBarHeader"
          :message="colorMessageBarMessage">
          <template v-if="statusTagIsUpdated" #icon>
            <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
          </template>
        </color-message-bar>

        <div class="size-text-24px bold-weight bottom-20">
          {{ opportunity.postTitle }}
        </div>

        <div class="size-text-17px bottom-20" v-html="opportunity.opportunityDescription"></div>

        <hr class="opps-hr top">

        <div class="row tight-columns d-flex-not-xs">
          <div class="col-sm-2">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="icon-container">
                <svg-icon name="location" class="base-icon"></svg-icon>
              </div>

              <div class="tight-lines">
                {{ location }}
              </div>
            </div>
          </div>

          <div class="col-sm-2 top-15-xs">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="icon-container">
                <svg-icon name="calendar" class="base-icon"></svg-icon>
              </div>

              <div class="tight-lines">
                {{ timeCommitment }}
              </div>
            </div>
          </div>

          <div class="col-sm-2 top-15-xs">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="icon-container">
                <svg-icon :name="siteIcon" class="base-icon"></svg-icon>
              </div>

              <div class="tight-lines">
                {{ site }}
              </div>
            </div>
          </div>

          <div class="col-sm-2 top-15-xs">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="icon-container">
                <svg-icon name="dollar" class="base-icon"></svg-icon>
              </div>

              <div class="tight-lines">
                ${{ opportunity.rateMin }} &ndash; ${{ opportunity.rateMax }}/hr
              </div>
            </div>
          </div>

          <div v-if="bars.length" class="col-sm-2 top-15-xs">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="icon-container">
                <svg-icon name="ribbon" class="base-icon"></svg-icon>
              </div>

              <div class="tight-lines text-ellipsis">
                {{ bars }}
              </div>
            </div>
          </div>

          <div class="col-sm-2 top-15-xs">
            <div class="height-100 vertical-center flex-center-not-xs">
              <div class="tight-lines">
                Reference #{{ opportunity.counselRequestId }}
              </div>
            </div>
          </div>
        </div>

        <hr class="opps-hr bottom">

        <div v-if="hasPracticeAreas" class="bottom-30">
          <div class="size-text-15px bold-weight offset-underline bottom-15">
            Practice Areas
          </div>

          <div class="d-flex wrap col-gap-5 row-gap-5 bottom-5">
            <color-tag
              v-for="practiceArea in opportunity.practiceAreas"
              :key="practiceArea"
              color-pattern="light-purple">
              {{ practiceArea }}
            </color-tag>
          </div>
        </div>

        <div v-if="hasKeyDetails" class="bottom-30">
          <div class="size-text-15px bold-weight offset-underline bottom-15">
            Key Details
          </div>

          <div class="size-text-14px" v-html="opportunity.keyDetails"></div>
        </div>

        <div v-if="hasAdditionalStatusDetails" class="bottom-30">
          <div class="size-text-15px bold-weight offset-underline bottom-15">
            Status
          </div>

          <div class="size-text-14px" v-html="opportunity.additionalStatusDetails"></div>
        </div>

        <button v-if="!displayForm" type="button" class="primary-btn-purple apply-btn" @click="openForm">Apply For <span class="hidden-xs">This </span>Opportunity</button>

        <opportunity-form
          v-show="displayForm"
          ref="applicationForm"
          class="top-40"
          :opportunity="opportunity"
          :on-submit="createOpportunityResponse"
          :on-cancel="closeForm">
        </opportunity-form>
      </div>

      <div v-else>
        <div class="size-text-24px bold-weight bottom-20">
          Application Successfully Submitted!
        </div>

        <div class="size-text-14px bottom-20">
          Thank you for applying to this opportunity. The Priori team will be in touch if you are a match. Please check your email for confirmation and future updates.
        </div>

        <div class="size-text-14px bottom-30">
          <b>If you’re interested in joining the Priori Marketplace Provider Network,</b> learn more and sign up below. You’ll be notified of new opportunities and can better keep track of your applications.
        </div>

        <div class="join-mp-button">
          <a href="/lawyer-signup" class="primary-btn-purple" target="_self">Join Marketplace<span class="hidden-xs"> Provider Network</span></a>
        </div>
      </div>
    </div>
  </opportunities-layout>
</template>

<script>
import { capitalize } from 'lodash';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';

import ColorTag from 'vue-app/shared/components/color-tag.vue';
import ColorMessageBar from 'vue-app/shared/components/color-message-bar.vue';
import OpportunitiesLayout from 'vue-app/public/opportunities/opportunities-layout.vue';
import OpportunityForm from 'vue-app/public/opportunities/form.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'LawyerOpportunitiesShow',

  components: {
    ColorTag,
    ColorMessageBar,
    OpportunitiesLayout,
    OpportunityForm,
    SvgIcon
  },

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    createOpportunityResponse: {
      type: Function,
      required: true
    },

    submittedApplication: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      displayForm: false
    };
  },

  computed: {
    shouldShowTag() {
      return ['new', 'updated'].includes(this.opportunity.statusTag);
    },

    statusTagIsNew() {
      return this.opportunity.statusTag === 'new';
    },

    statusTagIsUpdated() {
      return this.opportunity.statusTag === 'updated';
    },

    colorMessageBarHeader() {
      return this.statusTagIsNew ? 'NEW' : capitalize(this.opportunity.statusTag);
    },

    colorMessageBarColor() {
      return this.statusTagIsNew ? 'green' : 'yellow';
    },

    colorMessageBarMessage() {
      return this.statusTagIsNew ? 'This opportunity was recently posted.' : 'This posting has been recently updated.';
    },

    hasPracticeAreas() {
      return this.opportunity.practiceAreas.length > 0;
    },

    hasKeyDetails() {
      return this.opportunity.keyDetails && this.opportunity.keyDetails.length;
    },

    hasAdditionalStatusDetails() {
      return this.opportunity.additionalStatusDetails && this.opportunity.additionalStatusDetails.length;
    },

    location() {
      let location = '';

      if (this.opportunity.country === 'United States' && this.opportunity.state) {
        location += `${this.opportunity.state}, `;
      }

      location += this.opportunity.country;

      return location;
    },

    timeCommitment() {
      return SharedListsService.timeCommitments.find(tc => tc.slug === this.opportunity.timeCommitment).label;
    },

    siteIcon() {
      return this.opportunity.site === 'remote' ? 'wifi' : 'experience';
    },

    site() {
      return SharedListsService.sites.find(site => site.slug === this.opportunity.site).label;
    },

    bars() {
      return this.opportunity.requiredBars.map(bar => {
        if (bar.state) {
          return SharedListsService.stateAbbreviationFromName(bar.state);
        }
        else {
          return bar.country;
        }
      }).join(', ');
    },

    status() {
      return capitalize(this.opportunity.status);
    }
  },

  methods: {
    openForm() {
      this.displayForm = true;
      this.$nextTick(() => {
        window.scrollTo({ top: this.$refs.applicationForm.$el.offsetTop });
      });
    },

    closeForm() {
      this.displayForm = false;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .color-purple {
    color: $wave-end-purple;
  }

  :deep(.color-tag) {
    white-space: nowrap;
    padding: 3px 6px;
    font-size: 13px;
  }

  .opps-hr {
    border-color: $medium-gray;

    &.top {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &.bottom {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  .opportunity-nav-container {
    padding: 20px;
    background: $white;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }

    @media (min-width: $screen-md-min) {
      padding: 20px 50px;
    }
  }

  .opportunity-content-container {
    padding: 20px;
    background: $white;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 30px;
    }

    @media (min-width: $screen-md-min) {
      padding: 50px;
    }
  }

  .nav-btns-container {
    @media (min-width: $screen-sm-min) {
      width: 200px;
    }
  }

  .opportunity-info-col {
    padding-right: 10px;
    width: calc(100% / 6);

  }

  .icon-container {
    margin-right: 8px;
  }

  .apply-btn {
    @media (min-width: $screen-sm-min) {
      width: 230px;
    }
  }

  .join-mp-button {
    @media (min-width: $screen-sm-min) {
      width: 310px;
    }
  }
</style>

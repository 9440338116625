<template>
  <div id="client-account-info" class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Your Information
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

            <span v-if="isEditing">
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)">Save</a>
              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-content">
        <loading-section name="clientAccountInfo">
          <form role="form" name="form" class="a-form" novalidate>
            <div class="bottom-20">
              <text-input-horizontal label="First Name" name="firstName" :current-value="client.firstName" :edit-mode="isEditing" v-model="clientCopy.firstName"></text-input-horizontal>
            </div>

            <div class="bottom-20">
              <text-input-horizontal label="Last Name" name="lastName" :current-value="client.lastName" :edit-mode="isEditing" v-model="clientCopy.lastName"></text-input-horizontal>
            </div>

            <div class="bottom-20">
              <template v-if="isScout">
                <div class="row">
                  <div class="col-sm-4 semibold-weight">
                    Email
                  </div>

                  <div class="col-sm-8">
                    {{ client.email }}
                  </div>
                </div>
              </template>

              <template v-else>
                <email-input-horizontal label="Email" name="email" :current-value="client.email" :edit-mode="isEditing" v-model="clientCopy.email">
                  <div class="error-text top-5" v-show="client.unconfirmedEmail">
                    You should have received a confirmation email to your new email address ({{ client.unconfirmedEmail }}). You must click the link within it to finalize the change. If you did not receive the email, please contact Priori.
                  </div>
                </email-input-horizontal>
              </template>
            </div>

            <div class="bottom-20">
              <text-input-horizontal label="Job Title" name="role" :current-value="client.role" :edit-mode="isEditing" v-model="clientCopy.role" :novalidate="true"></text-input-horizontal>
            </div>

            <div class="bottom-20">
              <template v-if="isScout">
                <div class="row">
                  <div class="col-sm-4 semibold-weight">
                    Phone
                  </div>

                  <div class="col-sm-8">
                    {{ client.phoneNumber }}
                  </div>
                </div>
              </template>

              <template v-else>
                <text-input-horizontal label="Phone" name="phoneNumber" :current-value="client.phoneNumber" :edit-mode="isEditing" v-model="clientCopy.phoneNumber"></text-input-horizontal>
              </template>
            </div>

            <div class="row bottom-20">
              <div class="col-sm-4 semibold-weight">
                Time Zone
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  {{ client.timeZone || '—' }}
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <select v-model="clientCopy.timeZone" name="timeZoneSelect" id="time-zone" class="form-control">
                    <option v-for="timeZone in timeZones" :value="timeZone.railsName" :key="timeZone.railsName">
                      {{ timeZone.railsName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row bottom-20" v-if="!isEditing && !ssoEnabled">
              <div class="col-sm-4 semibold-weight">
                Password
              </div>

              <div class="col-sm-8">
                ******
                <span class="inline-block left-10">
                  <change-password-modal
                    :resource="client"
                    :on-save="savePassword"
                    prompt="Update Password"
                    :password-authentication="passwordAuthentication">
                  </change-password-modal>
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 semibold-weight">
                Allow Text Messages
                <span class="tooltip-question left-5" v-tooltip.top="{ text: 'Check this box if you would like to receive call/meeting notifications via text to the listed Phone Number.', customClass: 'kenny-styles k-tooltip tooltip-white small-text' }" v-if="isEditing">?</span>
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  {{ boolOptionLabel(client.smsAllowed) }}
                </div>

                <div v-if="isEditing">
                  <input type="checkbox" name="allowTextMessages" id="allow-text-messages" v-model="clientCopy.smsAllowed">
                </div>
              </div>
            </div>

            <div class="row top-30" v-if="isEditing">
              <div class="col-sm-4 col-md-3">
                <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(save)">Save</button>
              </div>

              <div class="col-sm-4 col-md-3 top-10-xs">
                <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
              </div>
            </div>
          </form>
        </loading-section>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TextInputHorizontal from 'vue-app/shared/components/text-input-horizontal.vue';
import EmailInputHorizontal from 'vue-app/shared/components/email-input-horizontal.vue';
import ChangePasswordModal from 'vue-app/marketplace/shared/change-password-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TimezoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import { isBoolean } from 'lodash';

export default {
  name: 'ClientAccountInfo',

  components: {
    ValidationObserver,
    TextInputHorizontal,
    EmailInputHorizontal,
    ChangePasswordModal,
    LoadingSection
  },

  props: {
    client: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      required: true
    },

    ssoEnabled: {
      type: Boolean,
      default: false
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    isScout: {
      type: Boolean,
      default: false
    },

    updatePassword: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      clientCopy: {}
    };
  },

  computed: {
    timeZones() {
      return TimezoneHelper.sortedByRailsName;
    }
  },

  methods: {
    startEdit() {
      this.clientCopy = Object.assign({}, this.client);
      this.isEditing = true;
    },

    cancelEdit() {
      this.isEditing = false;
    },

    save() {
      this.isEditing = false;

      this.saveClient(this.clientCopy);
    },

    boolOptionLabel(value) {
      if (isBoolean(value)) {
        return value ? 'Yes' : 'No';
      }
      else {
        return '—';
      }
    },

    saveClient(client) {
      LoadingService.loading('clientAccountInfo');

      this.onSave(client).finally(
        () => LoadingService.done('clientAccountInfo')
      );
    },

    savePassword(client) {
      LoadingService.loading('clientAccountInfo');

      return this.updatePassword({ password: client.password, passwordConfirmation: client.passwordConfirmation })
        .then(() => {
          window.location.href = '/sign-in';
        })
        .finally(() => {
          LoadingService.done('clientAccountInfo');
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a href="/client-app/settings" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>All Settings</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="medium-page-heading bottom-30">
            Payment Methods
          </div>

          <div class="shadowed-box">
            <div class="box-content symmetrical">
              <div class="table-responsive">
                <table id="payment-methods" class="table">
                  <thead>
                    <tr>
                      <th>Account Type</th>
                      <th></th>
                      <th>Last 4 Digits</th>
                      <th>Expiration</th>
                      <th>Verification Status</th>
                      <th>Default</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="account in visibleAccounts" :key="`account-${account.id}`">
                      <td>
                        {{ account.brand ? 'Credit Card' : 'Bank Account' }}
                      </td>

                      <td>
                        <img :src="account.imagePath" :alt="account.brand" width="40" :title="account.brand" v-if="account.paymentMethodType === 'card'">
                      </td>

                      <td>
                        {{ account.last4 }}
                      </td>

                      <td>
                        <span v-if="account.paymentMethodType === 'card'"><span v-if="account.expMonth">{{ account.expMonth }}/</span>{{ account.expYear || '&mdash;' }}</span>
                        <span v-if="account.paymentMethodType !== 'card'">&mdash;</span>
                      </td>

                      <td>
                        <a :href="`/client-app/bank-accounts/${account.id}/verify`" v-show="account.paymentMethodType === 'bank_account' && !account.verified">Click to verify</a>
                        <span v-show="account.verified">Verified</span>
                        <span v-show="account.paymentMethodType === 'card'">&mdash;</span>
                      </td>

                      <td>
                        <input type="radio" name="isDefault" :value="account.id" v-model="user.defaultPaymentMethod.id" @change="defaultChanged(account)">
                      </td>

                      <td>
                        <button type="button" class="button tiny-header-text red-text" role="button" @click="onDelete(account)" v-if="hasMoreThanOneActive">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5 col-md-4">
                  <a href="/client-app/payment-methods/new" class="nv-button-blue smaller">Add Payment Method</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <request-confirmation
      ref="confirmDelete"
      message="Are you sure you want to delete this payment method?"
      @on-modal-yes="deletePaymentMethod">
    </request-confirmation>

    <priori-modal ref="deletingDefault" modal-id="delete-modal" title="Notice">
      <div class="size-text-base left-10 right-10">
        You cannot delete your default payment method. To delete this payment method, you must first select a different payment method as your default.
      </div>
    </priori-modal>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';

import PaymentMethod from 'resources/payment-method.js';
import StripeCustomer from 'resources/stripe-customer.js';

import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

import { vPull } from 'misc/vue-helpers';
import { mapState } from 'pinia';
import { sortBy } from 'lodash';

export default {
  name: 'PaymentMethodsIndex',

  components: {
    SvgIcon,
    PrioriModal,
    RequestConfirmation
  },

  data() {
    return {
      paymentMethodToDelete: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    paymentMethods() {
      return sortBy(this.user.paymentMethods, 'id');
    },

    visibleAccounts() {
      return this.paymentMethods.filter(pm => pm.visible !== false);
    },

    activePaymentMethods() {
      return this.paymentMethods.filter(pm => pm.active !== false);
    },

    hasMoreThanOneActive() {
      return this.activePaymentMethods.length > 1;
    }
  },

  methods: {
    isDefault(paymentMethod) {
      return paymentMethod.id === this.user.defaultPaymentMethod.id;
    },

    onDelete(paymentMethod) {
      if (this.isDefault(paymentMethod)) {
        this.$refs.deletingDefault.openModal();
      }
      else {
        this.paymentMethodToDelete = paymentMethod;
        this.$refs.confirmDelete.openModal();
      }
    },

    deletePaymentMethod() {
      this.paymentMethodToDelete.active = false;

      PaymentMethod.update({ id: this.paymentMethodToDelete.id, paymentMethod: this.paymentMethodToDelete }).then(
        () => {
          vPull(this.user.paymentMethods, this.paymentMethodToDelete);
          this.paymentMethodToDelete.visible = false;
          this.paymentMethodToDelete = null;
        },
        () => {
          NotificationService.error('There was a problem deleting your payment method.');
        }
      );
    },

    defaultChanged(paymentMethod) {
      const params = {
        id: this.user.stripeCustomerId,
        stripeCustomer: { defaultPaymentMethodId: paymentMethod.id }
      };

      StripeCustomer.update(params).catch(() => {
        NotificationService.error('There was a problem changing your default payment method.');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .button {
    background: transparent;
    border: 0;
  }
</style>

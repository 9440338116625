<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container">
        <div class="dashboard">
          <div class="row">
            <div class="col-md-8 bottom-30">
              <dash-banner :client="client"></dash-banner>
            </div>

            <div class="col-md-4 bottom-30">
              <dash-actions :lawyers="lawyers"></dash-actions>
            </div>
          </div>

          <div class="bottom-30" v-if="counselRequests.length">
            <requests :requests="counselRequests"></requests>
          </div>

          <dismissible-cta
            class="bottom-30"
            context="dashboard"
            id="engagement-letter-templates">
            <span>
              Do you need an engagement letter?

              <a href="/client-app/documents/templates" class="semibold-weight">Check out our engagement letter templates!</a>
            </span>
          </dismissible-cta>

          <div class="bottom-30">
            <open-projects
              :matters="matters"
              :current-user="client"
              @message-lawyer="messageUser">
            </open-projects>
          </div>

          <div class="bottom-30">
            <call-schedule
              :consultations="consultations"
              :on-message-user="messageUser"
              :on-cancel-consultation="cancelConsultation"
              :on-reschedule-consultation="rescheduleConsultation">
            </call-schedule>
          </div>

          <div class="bottom-30">
            <workspace-users
              :current-user="client"
              :workspace="workspace"
              :workspace-users="workspaceUsers"
              :on-invite-sent="addUserToWorkspace"
              :on-message-user="messageUser">
            </workspace-users>
          </div>

          <div>
            <dash-totals :client="client"></dash-totals>
          </div>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>
  </div>
</template>

<script>
import DashBanner from 'vue-app/marketplace/client/dashboard/dash-banner.vue';
import DashActions from 'vue-app/marketplace/client/dashboard/dash-actions.vue';
import Requests from 'vue-app/marketplace/client/dashboard/requests.vue';
import OpenProjects from 'vue-app/marketplace/client/dashboard/open-projects.vue';
import DashTotals from 'vue-app/marketplace/client/dashboard/dash-totals.vue';
import CallSchedule from 'vue-app/marketplace/shared/call-schedule/call-schedule.vue';
import WorkspaceUsers from 'vue-app/marketplace/client/workspaces/workspace-users.vue';
import DismissibleCta from 'vue-app/marketplace/shared/dismissible-cta.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';
import Client from 'resources/client.js';
import Matter from 'resources/marketplace/matter.js';
import CounselRequest from 'resources/marketplace/counsel-request.js';
import Consultation from 'resources/marketplace/consultation.js';
import MessagingService from 'services/messaging-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useAppointmentWatcherStore from 'vue-app/stores/marketplace/appointment-watcher.js';
import { mapState } from 'pinia';

export default {
  name: 'ClientDashboardIndex',

  components: {
    DashBanner,
    DashActions,
    Requests,
    OpenProjects,
    DashTotals,
    CallSchedule,
    WorkspaceUsers,
    DismissibleCta,
    SendMessageModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      client: this.initData.client,
      workspace: this.initData.workspace,
      lawyers: this.initData.lawyers,
      matters: [],
      consultations: [],
      workspaceUsers: [],
      counselRequests: [],
      messageRecipients: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser', timeZone: 'timeZone' })
  },

  mounted() {
    this.loadWorkspaceUsers();
    this.loadMatters();
    this.loadCounselRequests();
    this.loadConsultations();

    useAppointmentWatcherStore().$subscribe(() => {
      this.loadCounselRequests();
      this.loadConsultations();
    });
  },

  methods: {
    loadWorkspaceUsers() {
      LoadingService.around('workspaceUsers', () => {
        return Client.query().then((clients) => {
          this.workspaceUsers = clients;
        });
      });
    },

    loadMatters() {
      LoadingService.around('matters', () => {
        return Matter.query({ view: 'client_recent' }).then((matters) => {
          this.matters = matters;
        });
      });
    },

    loadCounselRequests() {
      LoadingService.around('counselRequests', () => {
        return CounselRequest.query({ dashboard: true }).then((counselRequests) => {
          this.counselRequests = counselRequests;
        });
      });
    },

    loadConsultations() {
      LoadingService.around('consultations', () => {
        return Consultation.query({ currentAndFuture: true }).then((consultations) => {
          consultations.forEach(consult => consult.setTimeZone(this.timeZone));
          this.consultations = consultations;
        });
      });
    },

    messageUser(user) {
      this.messageRecipients = [user];
      this.$refs.messageModal.openModal();
    },

    consultationChangeUpdates() {
      this.loadCounselRequests();
      this.loadConsultations();
      MessagingService.refresh();
    },

    cancelConsultation(appointment, reason) {
      const participant = appointment.participantFor(this.user);
      return appointment.cancel(reason, participant).then(this.consultationChangeUpdates);
    },

    rescheduleConsultation(reason, consultation, availability) {
      const appointment = consultation.appointment;
      const participant = appointment.participantFor(this.user);
      return appointment.reschedule(reason, consultation, availability, participant).then(this.consultationChangeUpdates);
    },

    addUserToWorkspace(user) {
      this.workspaceUsers.push(user);
    }
  }
};
</script>

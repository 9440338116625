import { DateTime } from 'luxon';

class Timeslot {
  constructor(time) {
    this.time = time;
    this.utcTime = time.toUTC();
  }

  date(opts = {}) {
    return (opts.utc ? this.utcTime : this.time).toFormat('y-LL-dd');
  }

  hour(opts = {}) {
    return (opts.utc ? this.utcTime : this.time).toFormat('HH:mm');
  }

  equals(other) {
    return this.utcTime.equals(other.utcTime);
  }

  asDate() {
    return this.time.toJSDate();
  }

  isPast() {
    return this.time < DateTime.now();
  }

  isTime(localDateStr, localTimeStr) {
    return this.date() === localDateStr && this.hour() === localTimeStr;
  }

  isOnDate(date) {
    return date.toFormat('y-LL-dd') === this.time.toFormat('y-LL-dd');
  }

  inZone(zone) {
    return new this.constructor(this.time.setZone(zone));
  }

  label(opts = {}) {
    if (opts.timeZone) {
      return this.inZone(opts.timeZone).label({ format: opts.format });
    }
    else {
      return opts.format ? this.time.toLocaleString(DateTime[opts.format]) : this.time.toFormat('h:mm a');
    }
  }
}

export default Timeslot;

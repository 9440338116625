<template>
  <priori-modal ref="modal" title="Rehire Lawyer" @on-modal-close="reset">
    <template #modal-trigger>
      <div :class="['group-action', { disabled: !lawyers.length }]" @click="open">
        <svg-icon name="rehire" class="base-icon"></svg-icon>Rehire Lawyer
      </div>
    </template>

    <template #default="{ closeModal}">
      <validation-observer v-slot="{ handleSubmit }" ref="validationObserver">
        <form role="form" name="form" class="form a-form" novalidate>
          <div class="bottom-30">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <lawyer-dropdown
                ref="lawyerDropdown"
                v-model="selectedLawyer"
                :lawyers="lawyers"
                :invalid="errors.length > 0">
              </lawyer-dropdown>

              <div class="error-text top-5" v-if="errors.length">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <validation-provider rules="required" v-slot="{ errors }">
            <label for="message">Project Details <span class="inline-help required">*</span></label>

            <textarea name="message" id="message" :class="['form-control', { 'has-error': errors.length }]" placeholder="Enter project details..." rows="6" v-model="message"></textarea>

            <div class="error-text top-5" v-if="errors.length">
              {{ errors[0] }}
            </div>
          </validation-provider>

          <div class="row tight-columns top-30">
            <div class="col-sm-4">
              <button type="button" class="primary-btn-blue a-button-size" @click="handleSubmit(submit)">Send</button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LawyerDropdown from 'vue-app/marketplace/client/shared/lawyer-dropdown.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Lawyer from 'resources/lawyer.js';

export default {
  name: 'RehireLawyer',

  components: {
    LawyerDropdown,
    PrioriModal,
    SvgIcon,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawyers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedLawyer: null,
      message: ''
    };
  },

  methods: {
    open() {
      if (this.lawyers.length > 0) {
        this.$refs.modal.openModal();
      }
    },

    reset() {
      this.selectedLawyer = null;
      this.message = '';
      this.$refs.validationObserver.reset();
      this.$refs.lawyerDropdown.reset();
    },

    submit() {
      LoadingService.loading('rehireLawyer');

      Lawyer.rehire(this.selectedLawyer.id, { text: this.message })
        .then(() => {
          NotificationService.success('Your message has been sent successfully. You can access your messages from your dashboard and will receive an email alerting you when you receive a response.');
          this.$refs.modal.closeModal();
          LoadingService.done('rehireLawyer');
        });
    }
  }
};
</script>

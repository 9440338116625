<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="`/client-app/requests/${counselRequest.id}`" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Request: {{ counselRequest.nameForClientsReference }}</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="lawyer-profile">
            <div class="shadowed-box bottom-30">
              <div class="box-content">
                <profile-header
                  :possible-lawyer="possibleLawyer"
                  :lawyer="lawyer"
                  :on-ask-question="askQuestion"
                  :on-request-proposal="requestProposal"
                  :on-reject-lawyer="rejectLawyer"
                  :on-schedule-appointment="startSchedulingAppt"
                  :on-update-appointment="startUpdatingAppt"
                  :on-confirm-appointment="startConfirmingAppt"
                  :on-hire-lawyer="hireLawyer">
                </profile-header>
              </div>
            </div>

            <dismissible-cta
              class="bottom-30"
              :context="'rfp-lawyer-profile'"
              :id="'engagement-letter-templates'"
              :dismissible="false">
              <span>
                Do you need an engagement letter?
                <a href="/client-app/documents/templates" class="semibold-weight">Check out our engagement letter templates!</a>
              </span>
            </dismissible-cta>

            <div class="shadowed-box bottom-30" v-if="hasNoteAboutLawyer">
              <div class="box-content">
                <div class="project-manager-row">
                  <div class="manager-photo">
                    <profile-photo :user="projectManager"></profile-photo>
                  </div>

                  <div class="manager-note">
                    <div class="bottom-10">
                      <span class="semibold-weight">Note from {{ projectManager.firstName }}</span>
                      <span class="right-10">({{ projectManager.title || 'Project Manager' }})</span>
                      <button type="button" class="pseudo-link-blue semibold-weight" @click="askProjectManager">Send Message</button>
                    </div>

                    <div>
                      <span class="simple-text">{{ possibleLawyer.noteAboutLawyer }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottom-30">
              <loading-section name="lawyerProfile" :render-after-loading="true">
                <lawyer-profile
                  :lawyer-profile="profile"
                  :lawyer="lawyer"
                  :possible-lawyer="possibleLawyer"
                  :viewing-as-client="true">
                </lawyer-profile>
              </loading-section>
            </div>

            <div class="shadowed-box">
              <div class="box-content">
                <div class="row tight-columns vertical-center-not-xs">
                  <div class="col-sm-2">
                    <svg-icon name="ask-lawyer" class="base-icon largest"></svg-icon>
                  </div>

                  <div class="col-sm-6 col-md-7 size-text-base semibold-weight top-10-xs">
                    Still have questions? Ask the attorney directly.
                  </div>

                  <div class="col-sm-4 col-md-3 top-20-xs">
                    <button type="button" class="nv-button-white a-button-size" @click="askQuestion">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <send-message-modal ref="messageModal" :recipients="messageRecipients"></send-message-modal>

    <reject-lawyer-modal ref="rejectLawyerModal" :possible-lawyer="possibleLawyer" :on-lawyer-rejected="dismissRejectLawyerModal"></reject-lawyer-modal>

    <error-modal ref="rejectionError" notice="You can't reject any lawyers for this request since you've already made a selection."></error-modal>

    <scheduling-modal
      v-if="schedulingAppt"
      ref="schedulingModal"
      :appointment="newAppointment"
      :possible-participants="possibleApptParticipants"
      :selected-participants="selectedApptParticipants"
      :on-submit="scheduleAppointment">
    </scheduling-modal>

    <scheduling-modal
      v-if="updatingAppt"
      ref="updatingModal"
      :appointment="appointment"
      :on-cancel-appointment="startCancellingAppt"
      :on-submit="updateAppointment">
    </scheduling-modal>

    <choose-time-modal
      v-if="confirmingAppt"
      ref="chooseTimeModal"
      :appointment="appointment"
      :on-suggest-alternative-times="startUpdatingAppt"
      :on-request-cancellation="startCancellingAppt"
      :on-submit="confirmAppointment">
    </choose-time-modal>

    <cancelling-modal
      v-if="cancellingAppt"
      ref="cancellingModal"
      :appointment="appointment"
      :other-participants="otherApptParticipants"
      :on-submit="cancelAppointment">
    </cancelling-modal>
  </div>
</template>

<script>
import ProfileHeader from 'vue-app/marketplace/client/requests/profile-header.vue';
import DismissibleCta from 'vue-app/marketplace/shared/dismissible-cta.vue';
import LawyerProfile from 'vue-app/marketplace/shared/lawyers/lawyer-profile.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SendMessageModal from 'vue-app/shared/components/send-message-modal.vue';
import RejectLawyerModal from 'vue-app/marketplace/client/requests/reject-lawyer-modal.vue';
import ErrorModal from 'vue-app/shared/components/error-modal.vue';
import SchedulingModal from 'vue-app/marketplace/shared/appointments/scheduling-modal.vue';
import ChooseTimeModal from 'vue-app/marketplace/shared/appointments/choose-time-modal.vue';
import CancellingModal from 'vue-app/marketplace/shared/appointments/cancelling-modal.vue';

import Matter from 'resources/marketplace/matter.js';
import PossibleLawyer from 'resources/marketplace/possible-lawyer.js';
import LawyerProfileResource from 'resources/lawyer-profile.js';
import Appointment from 'resources/marketplace/appointment.js';
import Consultation from 'resources/marketplace/consultation.js';
import MessagingService from 'services/messaging-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useAppointmentWatcherStore from 'vue-app/stores/marketplace/appointment-watcher.js';
import { mapState } from 'pinia';
import { scrollToTop } from 'misc/ui-helpers.js';

export default {
  name: 'RequestsProfile',

  components: {
    ProfileHeader,
    DismissibleCta,
    LawyerProfile,
    SvgIcon,
    ProfilePhoto,
    LoadingSection,
    SendMessageModal,
    RejectLawyerModal,
    ErrorModal,
    SchedulingModal,
    ChooseTimeModal,
    CancellingModal
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      possibleLawyer: this.initData.possibleLawyer,
      lawyer: this.initData.possibleLawyer.lawyer,
      profile: {},
      messageRecipients: [],
      schedulingAppt: false,
      updatingAppt: false,
      confirmingAppt: false,
      cancellingAppt: false,
      newAppointment: {},
      possibleApptParticipants: [],
      selectedApptParticipants: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    counselRequest() {
      return this.possibleLawyer.counselRequest;
    },

    appointment() {
      return this.possibleLawyer.appointment;
    },

    hasAppointment() {
      return !!this.appointment?.id;
    },

    projectManager() {
      return this.possibleLawyer.projectManager();
    },

    hasNoteAboutLawyer() {
      return this.possibleLawyer.hasNoteAboutLawyer();
    },

    otherApptParticipants() {
      return this.appointment.participantsOtherThan(this.user).map(ap => ap.participant);
    }
  },

  beforeMount() {
    this.loadProfile();
    this.loadReviews();
  },

  mounted() {
    useAppointmentWatcherStore().$subscribe(this.reloadAppointment);
  },

  methods: {
    loadProfile() {
      LoadingService.around('lawyerProfile', () => {
        return LawyerProfileResource.get({ id: this.lawyer.lawyerProfileId }).then((profile) => {
          profile.concatRepresentativeClientsAndMatters();
          this.profile = profile;
        });
      });
    },

    loadReviews() {
      this.lawyer.loadReviews().then((lawyerWithReviews) => {
        this.lawyer = lawyerWithReviews;
      });
    },

    reloadAppointment() {
      if (!this.hasAppointment) { return Promise.resolve(); }

      return Appointment.get({ id: this.appointment.id }).then((appt) => {
        this.possibleLawyer.appointment = appt;
      });
    },

    askProjectManager() {
      this.messageRecipients = [this.projectManager];
      this.$refs.messageModal.openModal();
    },

    askQuestion() {
      this.possibleLawyer.lawyer.possibleLawyerId = this.possibleLawyer.id;
      this.messageRecipients = [this.possibleLawyer.lawyer];
      this.$refs.messageModal.openModal();
    },

    requestProposal() {
      const params = { id: this.possibleLawyer.id, possibleLawyer: { proposalRequested: true } };

      PossibleLawyer.update(params).then(() => {
        this.possibleLawyer.proposalRequested = true;
        this.reloadAppointment();
        NotificationService.success(`You have requested a formal proposal from ${this.possibleLawyer.lawyer.fullName}. You will be notified by email when the proposal is ready.`);
      });
    },

    startSchedulingAppt() {
      if (!this.possibleLawyer.ableToSchedule()) { return; }

      this.possibleLawyer.lawyer.possibleLawyerId = this.possibleLawyer.id;

      this.newAppointment = Appointment.newAppointment();
      this.possibleApptParticipants = [this.possibleLawyer.lawyer];
      this.selectedApptParticipants = [this.possibleLawyer.lawyer];

      return Consultation.scheduledTimesFor(this.user).then((conflictingTimes) => {
        this.newAppointment.conflictingTimes.consultations = conflictingTimes;
        this.schedulingAppt = true;
        this.$nextTick(() => { this.$refs.schedulingModal.initModal(true); });
      });
    },

    scheduleAppointment(appointment, availability, selectedParticipants) {
      return appointment.submitSuggestedTimes(this.user, availability, selectedParticipants).then((newAppts) => {
        MessagingService.refresh();
        this.possibleLawyer.appointment = newAppts[0];
      });
    },

    startUpdatingAppt() {
      if (!this.possibleLawyer.ableToSchedule()) { return; }

      this.stopConfirmingAppt();
      this.updatingAppt = true;
      this.$nextTick(() => { this.$refs.updatingModal.initModal(); });
    },

    stopUpdatingAppt() {
      if (!this.updatingAppt) { return; }
      this.$refs.updatingModal.closeModal();
    },

    updateAppointment(appointment, availability) {
      const participant = appointment.participantFor(this.user);

      return appointment.updateSuggestedTimes(participant, availability).then((appt) => {
        MessagingService.refresh();
        this.possibleLawyer.appointment = appt;
      });
    },

    startConfirmingAppt() {
      if (!this.possibleLawyer.ableToSchedule()) { return; }

      this.confirmingAppt = true;
      this.$nextTick(() => { this.$refs.chooseTimeModal.initModal(); });
    },

    stopConfirmingAppt() {
      if (!this.confirmingAppt) { return; }
      this.$refs.chooseTimeModal.closeModal();
    },

    confirmAppointment(timeslot) {
      const params = {
        id: this.possibleLawyer.appointment.id,
        appointment: {
          consultationsAttributes: [{ startsAt: timeslot.asDate() }]
        }
      };

      return Appointment.update(params).then((appt) => {
        MessagingService.refresh();
        this.counselRequest.scheduledAppointmentsCount += 1;
        this.possibleLawyer.appointment = appt;
      });
    },

    startCancellingAppt() {
      this.stopUpdatingAppt();
      this.stopConfirmingAppt();
      this.cancellingAppt = true;
      this.$nextTick(() => { this.$refs.cancellingModal.initModal(); });
    },

    cancelAppointment(appointment, reason) {
      const participant = appointment.participantFor(this.user);

      return appointment.cancel(reason, participant).then((appt) => {
        MessagingService.refresh();
        this.counselRequest.scheduledAppointmentsCount -= 1;
        this.possibleLawyer.appointment = appt;
      });
    },

    rejectLawyer() {
      if (!this.possibleLawyer.selected() && !this.possibleLawyer.crCompleted()) {
        this.$refs.rejectLawyerModal.openRejectModal();
      }
      else {
        this.$refs.rejectionError.openModal();
      }
    },

    dismissRejectLawyerModal() {
      this.$refs.rejectLawyerModal.dismissModal();
      window.location = `/client-app/requests/${this.counselRequest.id}`;
    },

    hireLawyer() {
      return Matter.openWithoutPayment({ id: this.possibleLawyer.matter.id })
        .then(() => {
          NotificationService.success(`You’ve successfully accepted your proposal from ${this.lawyer.firstName} ${this.lawyer.lastName}. We have notified your lawyer and they will be in touch shortly to get started with the work. You can work directly with your lawyer from here and use the project page below to view project information, including invoices and shared documents.`, true);
          window.location = `/client-app/projects/${this.possibleLawyer.matter.id}`;
        })
        .catch((response) => {
          if ((response.response.data.errors || []).includes('already rejected')) {
            NotificationService.error('That lawyer has declined the project.', true);
          }
          else {
            NotificationService.error('There was a problem hiring the lawyer.', true);
          }

          window.location.reload();
          scrollToTop();
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="titlebar with-button">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-9 medium-page-heading">
          RFPs
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <a href="/scout-company/rfps/new" class="secondary-btn-blue">Create New RFP</a>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-20">
            <span class="semibold-weight">{{ totalRfqCount }}</span>
            <span>{{ pluralizedResultsString }}</span>
          </div>

          <client-rfq-index-table
            :rfqs="rfqs"
            :current-page="currentPage"
            :change-page="changePage"
            :on-sort="onSort"
            :total-rfq-count="totalRfqCount"
            :per-page="perPage"
            :on-delete="onDelete"
            :change-rfq-status="onChangeRfqStatus">
          </client-rfq-index-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientRfqIndexTable from 'vue-app/scout/client/rfqs/client-rfq-index-table.vue';

export default {
  name: 'ClientRfqIndex',

  components: {
    ClientRfqIndexTable
  },

  props: {
    rfqs: {
      type: Array,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    },

    totalRfqCount: {
      type: Number,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    },

    onChangeRfqStatus: {
      type: Function,
      required: true
    }
  },

  computed: {
    pluralizedResultsString() {
      return this.$pluralize('result', this.totalRfqCount);
    }
  }
};
</script>
